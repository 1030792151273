import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import { A, Strong } from './typography';

const StyledMd = ({ children }) => (
  <Markdown
    options={{
      overrides: {
        a: A,
        strong: Strong,
      },
    }}
  >
    {children}
  </Markdown>
);

StyledMd.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StyledMd;
