/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import { Wrapper, Content } from '../common/structure';
import { Heading } from '../common/typography';

import triangle from '../../assets/triangle.svg';

const FORM_NAME = 'bohacek-contact';

const FormWrapper = styled.form`
  max-width: 45rem;
`;

const Fields = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: -1rem;
`;

const Label = styled.div`
  position: absolute;
  top: 0.8rem;
  left: calc(1rem + 5px);
  font-weight: 700;
  opacity: 1;
  font-size: 18px;
  transition: top 0.2s ease-out, font-size 0.2s ease-out, opacity 0.2s ease-out;

  ${({ min }) =>
    min &&
    css`
      top: 6px;
      font-size: 14px;
      opacity: 0.5;
      &::after {
        content: ':';
      }
    `}
`;

const TextInput = styled.input`
  padding: 0.5rem 1rem;
  transition: padding-top 0.2s ease-out, border-color 0.2s ease-out;
  ${({ value }) =>
    value &&
    css`
      padding-top: 1.2rem;
    `}
  border: 5px ${({ theme }) => theme.colors.primary} solid;
  &:valid {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  width: 100%;
`;

const Field = styled.div`
  flex: 1;
  flex-basis: 20rem;
  position: relative;
  margin: 1rem;
`;

const LongField = styled(Field)`
  max-width: 30rem;
`;

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Triangle = styled.img`
  width: 1rem;
  transform: rotate(90deg);
`;

const ButtonText = styled.div``;

const SendButton = styled.button`
  margin-top: 1rem;
  height: 2rem;
  text-transform: uppercase;
  overflow: hidden;
  text-align: center;
  font-weight: 700;
  transition: border-radius 0.3s, border-color 0.3s, width 0.3s, height 0.3s,
    opacity 0.3s;
  border-width: 5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.primary};

  height: 3rem;
  width: 12rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  ${({ theme, state }) =>
    (state === 0 &&
      css`
        ${Triangle} {
          margin-right: 0.5rem;
          margin-left: -0.5rem;
        }
        &:disabled {
          opacity: 0.5;
          border-color: ${theme.colors.fg};
        }
      `) ||
    (state === 1 &&
      css`
        width: 3rem;
        border-radius: 3rem;
        ${Triangle} {
          margin-left: 5px;
        }
        ${ButtonText} {
          display: none;
        }
        animation: ${spin} 0.7s infinite linear;
        border-color: ${theme.colors.fg};
      `) ||
    (state === 2 &&
      css`
        ${Triangle} {
          display: none;
        }
        border-color: ${theme.colors.secondary};
      `) ||
    (state === 'e' &&
      css`
        width: 100%;
        max-width: 20rem;
        height: initial;
        padding: 0.5rem 1rem;
        color: ${theme.colors.primary};
        ${Triangle} {
          display: none;
        }
      `)};
`;

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const handleSubmit = ({ e, setState, data }) => {
  e.preventDefault();
  setState(1);

  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': FORM_NAME, ...data }),
  })
    .then(() => setState(2))
    .catch(err => {
      console.error(`[FORM: ${FORM_NAME}] ${err}`);
      setState('e');
    });
};

const handleChange = (target, setter, setState) => {
  const { value } = target;
  const { valid } = target.validity;

  setState(prev => (prev ? 0 : prev));
  setter({ value, valid });
};

const getKey = state => {
  switch (state) {
    case 0:
      return 'cta';
    case 1:
      return 'sending';
    case 2:
      return 'success';
    case 'e':
    default:
      return 'error';
  }
};

const Contact = ({ t }) => {
  const [name, setName] = useState({
    value: '',
    valid: false,
  });
  const [company, setCompany] = useState({
    value: '',
    valid: false,
  });
  const [mail, setMail] = useState({
    value: '',
    valid: false,
  });
  const [phone, setPhone] = useState({
    value: '',
    valid: false,
  });
  const [message, setMessage] = useState({
    value: '',
    valid: false,
  });

  const [state, setState] = useState(0);

  return (
    <Wrapper>
      <Content>
        <Heading>{t.title}</Heading>
        <FormWrapper
          name={FORM_NAME}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={e =>
            handleSubmit({
              e,
              setState,
              data: {
                name: name.value,
                company: company.value,
                mail: mail.value,
                phone: phone.value,
                message: message.value,
              },
            })
          }
        >
          {/* Hidden input for Netlify Forms */}
          <input type="hidden" name="form-name" value={FORM_NAME} />

          {/* Honey pot field to trick bots */}
          <input type="hidden" name="bot-field" />

          <Fields>
            <Field>
              <Label min={name.value}>{t.name}</Label>
              <TextInput
                type="text"
                name="name"
                onChange={e => handleChange(e.target, setName, setState)}
                value={name.value}
                required
              />
            </Field>
            <Field>
              <Label min={company.value}>{t.company}</Label>
              <TextInput
                type="text"
                name="company"
                onChange={e => handleChange(e.target, setCompany, setState)}
                value={company.value}
                required
              />
            </Field>
            <Field>
              <Label min={mail.value}>{t.mail}</Label>
              <TextInput
                type="email"
                pattern="^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})?$"
                name="mail"
                onChange={e => handleChange(e.target, setMail, setState)}
                value={mail.value}
                required
              />
            </Field>
            <Field>
              <Label min={phone.value}>{t.phone}</Label>
              <TextInput
                type="text"
                name="phone"
                onChange={e => handleChange(e.target, setPhone, setState)}
                value={phone.value}
                required
              />
            </Field>
            <LongField>
              <Label min={message.value}>{t.message}</Label>
              <TextInput
                rows="4"
                as="textarea"
                type="text"
                name="message"
                onChange={e => handleChange(e.target, setMessage, setState)}
                value={message.value}
                required
              />
            </LongField>
          </Fields>

          <SendButton
            state={state}
            disabled={
              state ||
              !(
                name.valid &&
                mail.valid &&
                phone.valid &&
                company.valid &&
                message.valid
              )
            }
          >
            <Triangle src={triangle} />
            <ButtonText>{t.send[getKey(state)]}</ButtonText>
          </SendButton>
        </FormWrapper>
      </Content>
    </Wrapper>
  );
};

Contact.propTypes = {
  t: PropTypes.object.isRequired,
};

export default Contact;
