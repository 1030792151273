import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import { Wrapper, Content } from '../common/structure';
import { Heading, Subtitle, ExternalA, A } from '../common/typography';

import projects from '../../data/projects';
import triangle from '../../assets/triangle.svg';
import StyledMd from '../common/StyledMd';
import media from '../../lib/media';

const Carousel = styled.article`
  overflow-x: hidden;
  position: relative;
  padding: 2rem 0;
`;

const Items = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin-left: ${({ offset, width }) => -offset * (width + 45)}px;
  transition: margin-left 0.3s ease-out;
`;

const ItemWrapper = styled.div`
  display: block;
  flex-shrink: 0;
  border-color: gray;
  border-width: 5px;
  border-style: solid;
  cursor: pointer;
  max-width: 400px;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.colors.primary} !important;
      cursor: default;
    `}

  & + & {
    margin-left: 2rem;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
`;

const Control = styled.div`
  color: blue;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${({ left }) =>
    left
      ? css`
          &::before {
            content: '← ';
          }
        `
      : css`
          &::after {
            content: ' →';
          }
        `}
`;

const Item = ({ fluid, id, active, select }) => (
  <ItemWrapper
    onClick={() => {
      select(id);
    }}
    active={active}
    className="carouselImg"
  >
    <Img fluid={fluid} />
  </ItemWrapper>
);

Item.propTypes = {
  fluid: PropTypes.any.isRequired,
  id: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
};

const Showcase = styled.article`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-top: 5px ${({ theme }) => theme.colors.primary} solid;
  border-bottom: 5px ${({ theme }) => theme.colors.primary} solid;
  padding: 2rem 0;
  position: relative;
`;

const Triangle = styled.img`
  position: absolute;
  height: 20px;
  width: 20px;
  top: -20px;
  left: ${({ width }) => width / 2 - 10 + 5}px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2;
  flex-basis: 20rem;
  ${media.mobileXL`
    padding: 0 2rem;
  `}
`;

const ImgWrapper = styled.div`
  max-height: 30rem;
  overflow-y: auto;
  max-width: 900px;
  flex: 3;
  flex-basis: 30rem;
  margin-top: 1rem;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`;

const Tag = styled.div`
  background-color: ${({ color }) => color};
  color: white;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 1rem;

  & + & {
    margin-left: 1rem;
  }
`;

const Desc = styled.div`
  margin-top: 2rem;
`;

const Projects = ({ t, p, imgs }) => {
  const [selected, select] = useState(0);
  const [width, setWidth] = useState(400);

  useEffect(() => {
    setWidth(document.querySelector('.carouselImg').clientWidth);

    window.addEventListener('resize', () =>
      setWidth(document.querySelector('.carouselImg').clientWidth)
    );
  }, []);

  return (
    <Wrapper>
      <Content>
        <Heading>{t.title}</Heading>

        <Carousel>
          <Items offset={selected} width={width}>
            {projects.map((x, i, arr) => (
              <Item
                key={i}
                id={x.second ? i - (arr.length - 4) : i}
                select={select}
                active={selected === i}
                fluid={imgs[`${x.short}Thumb`].childImageSharp.fluid}
              />
            ))}
          </Items>
          <Controls>
            <Control left onClick={() => select(cur => (cur ? cur - 1 : cur))}>
              {t.last}
            </Control>
            <Control
              onClick={() =>
                select(cur => (projects[cur + 1].second ? 0 : cur + 1))
              }
            >
              {t.next}
            </Control>
          </Controls>
        </Carousel>

        <Showcase>
          <Triangle src={triangle} width={width} />
          <Info>
            <Subtitle>{projects[selected].name}</Subtitle>
            <Tags>
              {projects[selected].tags.map((x, i) => (
                <Tag key={i} color={x.color}>
                  {x.name}
                </Tag>
              ))}
            </Tags>
            <ExternalA href={projects[selected].link}>
              <A external>{t.link}</A>
            </ExternalA>
            <Desc>
              <StyledMd>{p[projects[selected].short]}</StyledMd>
            </Desc>
          </Info>
          <ImgWrapper>
            <Img
              fluid={
                imgs[`${projects[selected].short}Full`].childImageSharp.fluid
              }
            />
          </ImgWrapper>
        </Showcase>
      </Content>
    </Wrapper>
  );
};

Projects.propTypes = {
  t: PropTypes.object.isRequired,
  p: PropTypes.object.isRequired,
  imgs: PropTypes.object.isRequired,
};

export default Projects;
