import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { Wrapper, Content } from '../common/structure';
import { Title } from '../common/typography';
import StyledMd from '../common/StyledMd';

import arrow from '../../assets/arrow.svg';
import bracket from '../../assets/bracket.svg';
import media from '../../lib/media';

const BriefWrapper = styled(Wrapper)`
  background: ${({ theme }) => theme.colors.bg};
  ${media.laptop`
    padding-top: 10rem;
  `}
`;

const ContentBox = styled.article`
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.mobileXL`
    align-items: flex-start;
  `}
  ${media.laptop`
    flex-direction: row;
  `}
  justify-content: space-between;
`;

const Text = styled.div`
  position: relative;
  padding: 1rem 0;
  border-width: 5px;
  border-color: ${({ theme }) => theme.colors.primary};
  border-style: solid;
  border-left-style: none;
  border-right-style: none;
  color: ${({ theme }) => theme.colors.fg};
  flex: 1;
  margin-bottom: 11rem;
  ${media.mobileXL`
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    border-left-style: solid;
    border-right-style: solid;
  `}
  ${media.laptop`
    margin-right: 2rem;
  `}
`;

const Photo = styled(Img)`
  border-radius: 50%;
  border: 5px ${({ theme }) => theme.colors.primary} solid;
`;

const Arrow = styled.img`
  width: 1.5rem;
  margin-bottom: 0.3rem;
  transition: margin-bottom 0.2s cubic-bezier(0.17, 0.84, 0.44, 1);
`;

const Bracket = styled.img`
  width: 2rem;
  margin-bottom: 0.5rem;
`;

const Name = styled.p`
  color: blue;
  font-size: 0.6em;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const Download = styled.a`
  position: absolute;
  bottom: -9.5rem;
  right: calc(50% - 3.45rem - 5px);
  ${media.mobileXL`
    bottom: calc(-7.5rem - 2rem - 30px);
    right: -5px;
  `}
  ${media.laptop`
    bottom: -4rem;
    right: -4rem;
  `}
  width: 7.5rem;
  height: 7.5rem;
  padding: 0.7rem;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.bg};
  border: 5px ${({ theme }) => theme.colors.primary} solid;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  &:hover {
    ${Arrow} {
      margin-bottom: 0.1rem;
    }
  }
`;

const Brief = ({ t, photo, lang }) => (
  <BriefWrapper>
    <Content>
      <Title color="primary">{t.title}</Title>
      <ContentBox>
        <Text>
          <StyledMd>{t.text}</StyledMd>
          <Download href={`/bohacek_cv-${lang}.pdf`} target="_blank">
            <Arrow src={arrow} />
            <Bracket src={bracket} />
            <Name>bohacek_cv.pdf ({t.size} KB)</Name>
          </Download>
        </Text>
        <Photo fixed={photo.childImageSharp.fixed} />
      </ContentBox>
    </Content>
  </BriefWrapper>
);

Brief.propTypes = {
  t: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Brief;
