export default {
  code: {
    name: 'Coding',
    color: '#d20006',
  },
  design: {
    name: 'Design',
    color: '#00b2b4',
  },
  team: {
    name: 'Team lead',
    color: '#d80069',
  },
  long: {
    name: 'Long-term',
    color: '#ff8912',
  },
};
