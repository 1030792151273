import tags from './tags';

export default [
  {
    name: 'NFCtron',
    short: 'nfctron',
    tags: [tags.code, tags.design, tags.long],
    link: 'https://www.nfctron.com/',
  },
  {
    name: 'Slavnosti růžového vína',
    short: 'srv',
    tags: [tags.code],
    link: 'https://www.slavnostiruzovehovina.cz/',
  },
  {
    name: 'Symtia',
    short: 'symtia',
    tags: [tags.code, tags.team],
    link: 'https://www.symtia.com/',
  },
  {
    name: 'Foodpark',
    short: 'foodpark',
    tags: [tags.code, tags.design],
    link: 'https://web.archive.org/web/20181220211132/https://www.foodpark.cz/',
  },
  {
    name: 'Čerstvá sváča',
    short: 'svaca',
    tags: [tags.code, tags.team],
    link:
      'https://web.archive.org/web/20190109080846/https://www.cerstvasvaca.cz/',
  },
  {
    name: 'Fresh & Tasty',
    short: 'fat',
    tags: [tags.code, tags.team],
    link:
      'https://web.archive.org/web/20180821075618/https://www.myfreshandtasty.com/',
  },
  {
    name: 'NFCtron',
    short: 'nfctron',
    second: true,
  },
  {
    name: 'Slavnosti růžového vína',
    short: 'srv',
    second: true,
  },
  {
    name: 'Symtia',
    short: 'symtia',
    second: true,
  },
  {
    name: 'Foodpark',
    short: 'foodpark',
    second: true,
  },
];
