import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withLocale } from '../lib/locale';

import Layout from '../Layout';
import Brief from '../components/home/Brief';
import Projects from '../components/home/Projects';
import Contact from '../components/home/Contact';

const Home = ({ pageContext: { locales }, data }) => (
  <Layout
    title={locales.home.meta.title}
    description={locales.home.meta.description}
    t={locales.common}
    main={1}
  >
    <Brief
      t={locales.home.brief}
      photo={data.profilePhoto}
      lang={locales.common.lang}
    />
    <Projects t={locales.home.projects} p={locales.projects} imgs={data} />
    <Contact t={locales.home.contact} />
  </Layout>
);

Home.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withLocale(Home);

export const query = graphql`
  fragment ThumbFragment on File {
    childImageSharp {
      fluid(quality: 80, maxWidth: 400) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  fragment FullFragment on File {
    childImageSharp {
      fluid(quality: 80, maxWidth: 900) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query {
    profilePhoto: file(relativePath: { eq: "bohacek.jpg" }) {
      childImageSharp {
        fixed(quality: 80, width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    nfctronThumb: file(relativePath: { eq: "projects/nfctron-thumb.jpg" }) {
      ...ThumbFragment
    }

    nfctronFull: file(relativePath: { eq: "projects/nfctron-full.jpg" }) {
      ...FullFragment
    }

    symtiaThumb: file(relativePath: { eq: "projects/symtia-thumb.jpg" }) {
      ...ThumbFragment
    }

    symtiaFull: file(relativePath: { eq: "projects/symtia-full.jpg" }) {
      ...FullFragment
    }

    srvThumb: file(relativePath: { eq: "projects/srv-thumb.jpg" }) {
      ...ThumbFragment
    }

    srvFull: file(relativePath: { eq: "projects/srv-full.jpg" }) {
      ...FullFragment
    }

    foodparkThumb: file(relativePath: { eq: "projects/foodpark-thumb.jpg" }) {
      ...ThumbFragment
    }

    foodparkFull: file(relativePath: { eq: "projects/foodpark-full.jpg" }) {
      ...FullFragment
    }

    fatThumb: file(relativePath: { eq: "projects/fat-thumb.jpg" }) {
      ...ThumbFragment
    }

    fatFull: file(relativePath: { eq: "projects/fat-full.jpg" }) {
      ...FullFragment
    }

    svacaThumb: file(relativePath: { eq: "projects/svaca-thumb.jpg" }) {
      ...ThumbFragment
    }

    svacaFull: file(relativePath: { eq: "projects/svaca-full.jpg" }) {
      ...FullFragment
    }

    sitePage {
      context {
        locales {
          common {
            lang

            contact {
              phone
              mail
            }

            errors {
              ie {
                title
                text
              }
            }
          }

          home {
            meta {
              title
              description
            }

            brief {
              title
              text
              alt
            }

            projects {
              title
              next
              last
              link
            }

            contact {
              title
              name
              company
              mail
              phone
              message
              files
              required
              send {
                cta
                sending
                success
                error
              }
            }
          }

          projects {
            nfctron
            srv
            symtia
            foodpark
            fat
            svaca
          }
        }
      }
    }
  }
`;
